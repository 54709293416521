/*
 *
 */

import React from 'react';
import Section from 'components/section';
import Text from 'components/text';
import Hero from 'components/hero';
import H3 from 'components/h3';
import Partners from 'components/partners';
import { useIntl, FormattedMessage } from 'react-intl';
import Helmet from 'react-helmet';


/*
 *
 */

export default function AboutUs () {

	const intl = useIntl();

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.about_us.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.about_us.meta_desc' })} />
			</Helmet>
			<Section>
				<Hero
					title={intl.formatMessage({ id: 'page.home.section.about.title' })}
					subtitle={intl.formatMessage({ id: 'page.home.section.about.subtitle' })}
				/>
			</Section>
		<Section>
				<Text>
					<H3>{intl.formatMessage({ id: 'page.about_us.section.partners.title' })}</H3>
					<Partners
						partners={[
							{
								name: 'CentralApp',
								description: intl.formatMessage({ id: 'page.home.partners.centralapp.desc' }),
								logo: '/img/centralapp.png',
								url: 'https://centralapp.com'
							},
							{
								name: 'Choco',
								description: intl.formatMessage({ id: 'page.home.partners.choco.desc' }),
								logo: '/img/choco.png',
								url: 'https://choco.com/fr-2/fr'
							},
							{
								name: 'Deliverect',
								description: intl.formatMessage({ id: 'page.home.partners.deliverect.desc' }),
								logo: '/img/deliverect.png',
								url: 'https://hubs.ly/H0BZbDY0'
							},
							{
								name: 'Deliveroo',
								description: intl.formatMessage({ id: 'page.home.partners.deliveroo.desc' }),
								logo: '/img/deliveroo.png',
								url: 'http://deliveroo.fr'
							},
							{
								name: 'Mapstr',
								description: intl.formatMessage({ id: 'page.home.partners.mapstr.desc' }),
								logo: '/img/mapstr.png',
								url: 'https://mapstr.com/'
							},
							{
								name: 'Rivalis',
								description: intl.formatMessage({ id: 'page.home.partners.rivalis.desc' }),
								logo: '/img/rivalis.png',
								url: 'https://www.pilotage-entreprise-rivalis.com/sos-restos?utm_source=centralapp&utm_medium=email&utm_campaign=SOS_RESTOS'
							},
							{
								name: 'Skello',
								description: intl.formatMessage({ id: 'page.home.partners.skello.desc' }),
								logo: '/img/skello.png',
								url: 'https://www.skello.io/'
							},
							{
								name: 'Swile',
								description: intl.formatMessage({ id: 'page.home.partners.swile.desc' }),
								logo: '/img/swile.png',
								url: 'https://www.swile.co/'
							},
							{
								name: 'Tiller',
								description: intl.formatMessage({ id: 'page.home.partners.tiller.desc' }),
								logo: '/img/tiller.png',
								url: 'https://www.tillersystems.com'
							}
						]}
					/>

					<p style={{ textAlign: 'center' }}>
						<FormattedMessage
							id="page.home.section.about.illustrations"
							values={{
								a: (...chunks) => (
									<a target="_blank" rel="noopener noreferrer" href="https://instagram.com/alixdebroux">
										{chunks}
									</a>
								)
							}}
						/>
					</p>
				</Text>
			</Section>
		</React.Fragment>
	);
}